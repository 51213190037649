/* eslint-disable import/extensions */
/* eslint-disable import/prefer-default-export */
import request from "@/libs/axios.js";
import config from "../../config/index";

const { baseUrl } = config;

// 根据条件分页获取任务列表
export function getScheduleList(data) {
  const url = `${baseUrl}schedule/v1/getList`;
  return request({
    url,
    method: "POST",
    data,
  });
}

// 批量逻辑删除任务
export function batchDeleteSchedule(taskScheduleIds) {
  const url = `${baseUrl}schedule/v1/batchDelete`;
  return request({
    url,
    method: "POST",
    data: {
      taskScheduleIds,
    },
  });
}

// 手动执行一次任务
export function executeSchedule(data) {
  const url = `${baseUrl}schedule/v1/execute`;
  return request({
    url,
    method: "POST",
    data: data,
  });
}

// 任务修改历史记录
export function updateHistorySchedule(taskScheduleId, current, size, orders) {
  const url = `${baseUrl}schedule/v1/updateHistory`;

  const data = {
    taskScheduleId,
    current,
    size,
    orders,
  };

  return request({
    url,
    method: "POST",
    data,
  });
}

//
export function getLayoutList(
  storeId,
  storeQueryType,
  current,
  size,
  orders,
  strategyName,
  strategyType
) {
  const url = `${baseUrl}layout/v1/getList`;

  const data = {
    storeId,
    storeQueryType,
    current,
    size,
    orders,
    strategyName,
    strategyType,
  };

  return request({
    url,
    method: "POST",
    data,
  });
}

// scenario/v1/getScenarioList
export function getScenarioList(
  merchantId,
  textKey,
  scenarioKey,
  isEffect,
  agentId
) {
  const url = `${baseUrl}scenario/v1/getScenarioList`;
  const data = {
    merchantId,
    textKey,
    scenarioKey,
    isEffect,
    agentId,
  };

  return request({
    url,
    method: "POST",
    data,
  });
}

export function getAllTagType(merchantId, source) {
  const url = `${baseUrl}tag/v1/getAllTagType`;
  const data = {
    merchantId,
  };
  return request({
    url,
    method: "POST",
    data,
  });
}

export function getCodeList(cateId) {
  const url = `${baseUrl}code/v1/getList`;
  const data = {
    cateId,
  };
  return request({
    url,
    method: "POST",
    data,
  });
}

export function getScenario(scenarioId) {
  const url = `${baseUrl}scenario/v1/getScenario`;
  const data = {
    scenarioId,
  };
  return request({
    url,
    method: "POST",
    data,
  });
}

// 复制任务
export function copySchedule(taskScheduleId) {
  const url = `${baseUrl}schedule/v1/copy`;
  return request({
    url,
    method: "POST",
    data: {
      taskScheduleId,
    },
  });
}

// 获取任务详情
export function getSchedule(taskScheduleId) {
  const url = `${baseUrl}schedule/v1/get?taskScheduleId`;

  const data = {
    taskScheduleId,
  };
  return request({
    url,
    method: "POST",
    data,
  });
}

// 创建任务
export function createSchedule(data) {
  const url = `${baseUrl}schedule/v1/create`;
  return request({
    url,
    method: "POST",
    data,
  });
}

// 更新任务
export function updateSchedule(data) {
  const url = `${baseUrl}schedule/v1/update`;
  return request({
    url,
    method: "POST",
    data,
  });
}

export function updateTaskState(data) {
  const url = `${baseUrl}schedule/v1/updateTaskState`;
  return request({
    url,
    method: "POST",
    data,
  });
}
export function templateImport(formData) {
  const url = `${baseUrl}template/v1/import`;
  return request({
    url,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}
export function templateExport(data) {
  const url = `${baseUrl}template/v1/export`;
  return request({
    url,
    method: "POST",
    data,
  });
}
export function templateShare(data) {
  const url = `${baseUrl}template/v1/share`;
  return request({
    url,
    method: "POST",
    data,
  });
}

export function getSimplifyList(params) {
  const url = `${baseUrl}item/v1/getSimplifyList`;
  return request({
    url,
    method: "POST",
    data: params,
  });
}

//
export function templatePreview(params) {
  const url = `${baseUrl}template/v1/preview`;
  return request({
    url,
    method: "POST",
    data: params,
  });
}

export function createTemplate(params) {
  const url = `${baseUrl}template/v1/create`;
  return request({
    url,
    method: "POST",
    data: params,
  });
}

export function updateTemplate(params) {
  const url = `${baseUrl}template/v1/update`;
  return request({
    url,
    method: "POST",
    data: params,
  });
}

export function creatItemSyncConfig(data) {
  return request({
    url: `${baseUrl}item/sync/config/v1/create`,
    data,
    method: "POST",
  });
}

export function updateItemSyncConfig(data) {
  return request({
    url: `${baseUrl}item/sync/config/v1/update`,
    data,
    method: "POST",
  });
}
// 接口配置启用
export function systemInterfaceEnble(data) {
  return request({
    url: `${baseUrl}item/sync/config/v1/enable`,
    data,
    method: "POST",
  });
}
// 接口配置停用
export function systemInterfaceDisable(data) {
  return request({
    url: `${baseUrl}item/sync/config/v1/disable`,
    data,
    method: "POST",
  });
}
// 模板批量删除
export function batchPhysicalDeleteTemplate(data) {
  return request({
    url: `${baseUrl}template/v1/batchDelete`,
    data,
    method: "POST",
  });
}
//根据模板id查询所属布局相关信息
export function getTemplateLump(data) {
  return request({
    url: `${baseUrl}layout/v1/getTemplateLump`,
    data,
    method: "POST",
  });
}



/*
 * 20230314add 获取任务列表接口
 */
export function getTaskList(data) {
  const url = `${baseUrl}task/v1/getList`;
  return request({
    url,
    method: "POST",
    data,
  });
}

/*
 * 20230314add 删除单个任务
 */
export function deleteTask(data) {
  const url = `${baseUrl}task/v1/delete`;
  return request({
    url,
    method: "POST",
    data,
  });
}


/*
 * 20230314add 批量删除任务
 */
export function batchDeleteTask(data) {
  const url = `${baseUrl}task/v1/batchDelete`;
  return request({
    url,
    method: "POST",
    data,
  });
}

// 修改任务顺序
export function taskExecutionSequence(data) {
  const url = `${baseUrl}schedule/v1/taskExecutionSequence`;
  return request({
    url,
    method: "POST",
    data: data,
  });
}
